import {useEffect, useMemo, useState} from 'react';
import TrackingContext from "./trackingContext";
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import {md5} from '@tools/md5';
import { v4 as uuidv4 } from 'uuid';
import { getCartItemPrices } from 'functions/parseSWPrices';
import { getUserSession } from '@tools/elevateClient';
import Head from "next/head";
import fetch from "react-storefront/fetch";
import useCartStore from '@services/stores/CartStore';
import useSessionStore from '@services/stores/SessionStore';
import useUserStore from '@services/stores/UserStore';
import { useRouter } from 'next/router';

export default function TrackingProvider({gtmId, children}) {
	const router = useRouter();
	const myNewportService = useUserStore((state) => state.service);
	const session = useSessionStore((state) => state.session);
	const cart = useCartStore((state) => state.cart);
	const {getEclubData} = useSessionStore((state) => state.methods);
	const [eclubData, setEclubData] = useState(getEclubData());
	const [trackingInitiated, setTrackingInitiated] = useState(false)
	const {config} = session;
	let timeout = setTimeout(() => {}, 1);

	const tagManagerArgs = {
		gtmId: gtmId
	}

	/* Tracking queue logic */
	let queue = [];

	function confirmEventDependency(dependency, values) {
		switch (dependency) {
			case 'cookie':
				let cookiesNotFound = false;

				values.forEach((value) => {
					if(!Cookies.get(value)) {
						cookiesNotFound = true;
					}
				});

				return !cookiesNotFound;
			case false:
			default:
				return true;
		}
	}

	function makeServerTrackingRequest(source = 'facebook', event = 'pageview', data = {}) {
		fetch(['/api/tracking', source, event].join('/'), {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			credentials: 'include',
			body: JSON.stringify(data)
		});
	}

	function consumeQueue() {
		let removeFromQueue = [];

		if(queue.length === 0 || isGoogleBot()) {
			return;
		}

		queue.forEach((event) => {
			if(!confirmEventDependency(event.dependency, event.values)) {
				return;
			}

			let eventId = event.id;
			makeServerTrackingRequest(event.service, event.endpoint, event.data);

			removeFromQueue.push(eventId);
		});

		queue = queue.filter((event) => {
			return removeFromQueue.indexOf(event.id) < 0;
		});
	}

	function addToQueue(item) {
		queue.push({
			id: item.id,
			dependency: item.dependency,
			values: item.values,
			service: item.service,
			endpoint: item.endpoint,
			data: item.data
		});

		window.requestAnimationFrame(() => {
			consumeQueue();
		});
	}

	function generateEventId() {
		return uuidv4();
	}

	function isGoogleBot() {
		let userAgent = navigator.userAgent;

		return userAgent.toLowerCase().indexOf("googlebot") >= 0;
	}

	function cartHasUpdated() {
		clearTimeout(timeout);

		timeout = setTimeout(() => {
			context.actions.cartUpdated(cart);
		}, 100);
	}

	function isInStoreDisplay() {
        return typeof window !== 'undefined' && window?.navigator?.userAgent?.indexOf('NEWPORT_INSTOREBROWSER') < 0 ? false : true;
    }

	const context = useMemo(() => {
		return {
			session: session,
			trackingInitiated,
			actions: {
				init() {
					/*
					* Listen to Cookie Bot event
					* */
					addEventListener('CookiebotOnLoad', (event) => {
						collectUTMTags();

						setTrackingInitiated(true);

						TagManager.dataLayer({
							dataLayer: {
								domain_country: config.language.languageCode.split('-')[1],
								event: 'tracking_init'
							}
						});
					});

					if (router?.basePath?.includes('newport.int')) {
						collectUTMTags();

						setTrackingInitiated(true);

						TagManager.dataLayer({
							dataLayer: {
								domain_country: config.language.languageCode.split('-')[1],
								event: 'tracking_init'
							}
						});
					}

					this.initTrackingScript();

					this.handleSoftLogin();
				},

				initTrackingScript() {
					/* Init TagManager */
					TagManager.initialize(tagManagerArgs);
				},

				getCurrency() {
					return context.session.config.currency;
				},

				pageView(title, type = undefined) {
					let eventId = generateEventId();

					/* TagManager */
					TagManager.dataLayer({
						dataLayer: {
							event: 'page_view_loaded',
							event_id: eventId,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							domain_country: config.language.languageCode.split('-')[1],
							email: eclubData?.email || undefined,
							data: {
								page_title: title,
								page_url: window.location.href,
								page_path: window.location.pathname,
								page_type: type
							}
						}
					});

					/* Add to async dependency queue */
					if (context.session.config.fbPixelId) {
						addToQueue({
							id: eventId,
							dependency: 'cookie',
							values: ['_fbp', process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE],
							service: 'facebook',
							endpoint: 'pageview',
							data: {
								eventId: eventId,
								email: eclubData?.email || undefined,
							}
						});
					}
				},

				addToCart({id, name, price, discount, brand, category, list, variant, quantity}) {
					let eventId = generateEventId();

					let item = {
						item_name: name,
						item_id: id,
						price: price,
						item_brand: brand || '',
						item_variant: variant || '',
						item_list_name: list || '',
						quantity: quantity
					}

					if(typeof category === 'string' || typeof category === 'undefined') {
						item['item_category'] = category || '';
					} else {
						item['item_category'] = category[0] || '';
						item['item_category2'] = category[1] || '';
						item['item_category3'] = category[2] || '';
						item['item_category4'] = category[3] || '';
					}

					console.log('AddToCart: ', item);

					TagManager.dataLayer({
						dataLayer: {
							event: 'add_to_cart',
							event_id: eventId,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							domain_country: config.language.languageCode.split('-')[1],
							email: eclubData?.email || undefined,
							ecommerce: {
								currency: this.getCurrency(),
								value: price * quantity,
								items: [item]
							}
						}
					});

					/* Make server tracking request */
					if (context.session.config.fbPixelId) {
						addToQueue({
							id: eventId,
							dependency: false,
							service: 'facebook',
							endpoint: 'addtocart',
							data: {
								eventId: eventId,
								currency: this.getCurrency(),
								email: eclubData?.email || undefined,
								product: {
									id: id,
									quantity: quantity,
									price: price
								}
							}
						});
					}
				},

				removeFromCart({id, name, price, discount, manufacturer, category, list, variant, quantity}) {
					let item = {
						item_name: name,
						item_id: id,
						price: price,
						item_brand: manufacturer,
						item_category: category,
						item_list_name: list,
						variant: variant,
						quantity: quantity
					}

					TagManager.dataLayer({
						dataLayer: {
							event: 'remove_from_cart',
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								currency: this.getCurrency(),
								value: price * quantity,
								items: [item]
							}
						}
					});
				},

				cartUpdated({lineItems}) {
					let eventId = generateEventId();

					let items = lineItems.filter((item) => {
						return item.type !== 'promotion';
					}).map((item) => {
						return {
							id: item.payload.productNumber,
							name: item.label,
							quantity: item.quantity,
							price: item.price.unitPrice
						}
					});

					TagManager.dataLayer({
						dataLayer: {
							event: 'cart_updated',
							event_id: eventId,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								currency_code: this.getCurrency(),
								cart: {
									items
								}
							}
						}
					});
				},

				click({type, text, identifier}) {
					TagManager.dataLayer({
						dataLayer: {
							event: 'click_interaction',
							domain_country: config.language.languageCode.split('-')[1],
							click: {
								type: type,
								text: text,
								identifier: identifier
							}
						}
					});
				},

				productImpressions(items) {
					TagManager.dataLayer({
						dataLayer: {
							event: 'product_impressions',
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								currency: this.getCurrency(),
								items: items.map((item) => {
									let {id, name, price, discount, brand, category, list, variant, quantity} = item;

									let impression = {
										item_name: name,
										item_id: id,
										price: price,
										discount: discount || 0,
										item_brand: brand || '',
										item_variant: variant || '',
										item_list_name: list || '',
										quantity: quantity
									}

									if(typeof category === 'string' || typeof category === 'undefined') {
										impression['item_category'] = category || '';
									} else {
										impression['item_category'] = category[0] || '';
										impression['item_category2'] = category[1] || '';
										impression['item_category3'] = category[2] || '';
										impression['item_category4'] = category[3] || '';
									}

									return impression;
								})
							}
						}
					});
				},

				productClick({id, name, price, discount, brand, category, list, variant, quantity}) {
					let item = {
						item_name: name,
						item_id: id,
						price: price,
						item_brand: brand || '',
						item_variant: variant || '',
						item_list_name: list || '',
						quantity: quantity
					}

					if(typeof category === 'string' || typeof category === 'undefined') {
						item['item_category'] = category || '';
					} else {
						item['item_category'] = category[0] || '';
						item['item_category2'] = category[1] || '';
						item['item_category3'] = category[2] || '';
						item['item_category4'] = category[3] || '';
					}

					console.log('Click: ', item);

					TagManager.dataLayer({
						dataLayer: {
							event: 'product_click',
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								items: [item]
							}
						}
					});
				},

				productDetails({id, name, price, discount, brand, category, list, variant, quantity}) {
					let eventId = generateEventId();

					let item = {
						item_name: name,
						item_id: id,
						price: price,
						item_brand: brand || '',
						item_variant: variant || '',
						item_list_name: list || '',
						quantity: quantity
					}

					if(typeof category === 'string' || typeof category === 'undefined') {
						item['item_category'] = category || '';
					} else {
						item['item_category'] = category[0] || '';
						item['item_category2'] = category[1] || '';
						item['item_category3'] = category[2] || '';
						item['item_category4'] = category[3] || '';
					}

					TagManager.dataLayer({
						dataLayer: {
							event: 'view_item',
							event_id: eventId,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							email: eclubData?.email || undefined,
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								currency: this.getCurrency(),
								value: price,
								items: [item]
							}
						}
					});

					/* Make server tracking request */
					if (context.session.config.fbPixelId) {
						addToQueue({
							id: eventId,
							dependency: 'cookie',
							values: ['_fbp', process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE],
							service: 'facebook',
							endpoint: 'viewcontent',
							data: {
								eventId: eventId,
								currency: this.getCurrency(),
								email: eclubData?.email || undefined,
								product: {
									id: id,
									price: price
								}
							}
						});
					}
				},

				productListing(categoryId, productIds) {
					let eventId = generateEventId();

					TagManager.dataLayer({
						dataLayer: {
							event: 'product_listing',
							event_id: eventId,
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								category_id: categoryId,
								listing: {
									productIds
								}
							}
						}
					});
				},

				checkoutCart({cartItems, value}) {
					let eventId = generateEventId();

					TagManager.dataLayer({
						dataLayer: {
							event: 'begin_checkout',
							event_id: eventId,
							domain_country: config.language.languageCode.split('-')[1],
							ecommerce: {
								currency: this.getCurrency(),
								value,
								items: cartItems
							}
						}
					});
				},

				purchase({order}) {
					let eventId = generateEventId();
					let eventTime = Math.floor(Date.now() / 1000);
					let promoCode = null;
					let currency = order?.currency?.isoCode;
					let items = order.lineItems.filter((item) => {
						if(item.type === 'promotion') {
							promoCode = item.payload.code;
						}

						return item.type !== 'promotion';
					}).map((item) => {
						let itemPrice = getCartItemPrices(item);

						return {
							item_id: item.payload.productNumber,
							item_name: item.label,
							price: itemPrice.price,
							brand: item.payload?.manufacturer || '',
							category: item.payload?.mainCategory || '',
							item_variant: item.payload?.options?.length > 0 ? item.payload.options.map((option) => {return option.option;}).join(' - ') : null,
							quantity: parseInt(item.quantity)
						}
					});

					/* TagManager */
					TagManager.dataLayer({
						dataLayer: {
							event: 'purchase',
							event_id: eventId,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							domain_country: config.language.languageCode.split('-')[1],
							email: order.orderCustomer?.email,
							ecommerce: {
								currency: currency,
								transaction_id: order.orderNumber,
								value: order.price?.totalPrice,
								coupon: promoCode,
								tax: order?.price?.totalPrice - order.price?.netPrice,
								net_price: order.price?.netPrice,
								net_price_without_shipping: order?.price?.netPrice - (order?.shippingTotal - order?.shippingCosts?.calculatedTaxes?.[0].tax),
								shipping: order.shippingTotal,
								email: order.orderCustomer?.email,
								email_hash: md5(order.orderCustomer?.email),
								items
							}
						}
					});

					// Facebook backend tracking
					if (context.session.config.fbPixelId) {
						addToQueue({
							id: eventId,
							dependency: 'cookie',
							values: ['_fbp', process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE],
							service: 'facebook',
							endpoint: 'purchase',
							data: {
								eventId: eventId,
								currency: currency,
								products: order.lineItems.filter((item) => {
									return item.type !== 'promotion';
								}).map((item) => {
									return {
										id: item.payload?.productNumber,
										price: item.price?.unitPrice,
										quantity: item.quantity
									}
								}),
								value: order.price?.totalPrice,
								email: order.orderCustomer?.email,
								dateOfBirth: order.orderCustomer?.customer?.birthday ? order.orderCustomer?.customer?.birthday?.split('T')[0].split('-').join('') : undefined,
								firstName: order.orderCustomer?.firstName
							}
						});
					}

					// Pinterest Conversion API Tracking
					if(config.language.locale === 'sv') {
						addToQueue({
							id: eventId,
							service: 'pinterest',
							endpoint: 'purchase',
							data: {
								eventId: eventId,
								eventTime: eventTime,
								market: 'SE',
								user_data: {
									em: order.orderCustomer?.email,
								},
								custom_data: {
									currency: currency,
									value: '' + order.price?.totalPrice,
									content_ids: order.lineItems.filter((item) => {
										return item.type !== 'promotion';
									}).map((item) => {
										return item.payload?.productNumber
									}),
								}
							}
						});
					}

					if(getUserSession()) {
						addToQueue({
							id: eventId,
							service: 'voyado',
							endpoint: 'purchase',
							data: {
								market: session.config.elevateMarket,
								customer: getUserSession(),
								lines: order.lineItems.filter((item) => {
									return item.type !== 'promotion';
								}).map((item) => {
									return {
										variantKey: item.payload?.productNumber,
										sellingPrice: item.price?.unitPrice,
										quantity: item.quantity
									}
								}),
							}
						});
					}
				},

				experiment(experiment, result) {
					let eventId = generateEventId();
					let eventTime = Math.floor(Date.now() / 1000);
					/* TagManager */
					TagManager.dataLayer({
						dataLayer: {
							event: 'experiment',
							event_id: eventId,
							eventTime: eventTime,
							external_id: Cookies.get(process.env.NEXT_PUBLIC_STOREFRONT_SESSION_COOKIE),
							experiment_id: experiment.name,
							result_id: result.featureId,
							variation_id: result.variationId,
						}
					});
				},

				async sendUTMTags() {
					let trackingData;

					try {
						trackingData = sessionStorage.getItem('trackingData') ? JSON.parse(sessionStorage.getItem('trackingData')) : false;
					} catch (e) {
						console.log(e);
					}

					if(trackingData) {
						await fetch('/api/tracking/utm-tags', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
							},
							credentials: 'include',
							body: JSON.stringify({trackingData})
						});
					}
				},

				async sendDiscoveryKey() {
					let discoveryKey = myNewportService.getLocalDiscoveryKey();

					if(discoveryKey) {
						await fetch('/api/tracking/custom-fields', {
							method: 'POST',
							headers: {
								'Accept': 'application/json',
								'Content-Type': 'application/json',
							},
							credentials: 'include',
							body: JSON.stringify({ customFields: { discovery_key: discoveryKey } })
						});
					}
				},

				removeUTMTags() {
					sessionStorage.getItem('trackingData') ? sessionStorage.removeItem('trackingData') : false;
				},

				getEclubString() {
					const queryString = window.location.search;
					const urlParams = new URLSearchParams(queryString);

					return urlParams.get('eclub');
				},

				async handleSoftLogin() {
					const eclubString = this.getEclubString();

					if(eclubString) {
						try {
							const eclubResponse = await fetch('/api/voyado/eclub', {
								method: 'POST',
								body: JSON.stringify({
									eclubString
								})
							});

							let eclubResponseJson = await eclubResponse.json();

							if(eclubResponseJson.status === 'success') {
								let eclubData = eclubResponseJson.data;
								setEclubData(eclubData);
								localStorage.setItem('eclubData', JSON.stringify(eclubData));

								//Dispatch Eclub login event
								document.dispatchEvent(new CustomEvent("eclub-login"));
							}
						} catch (e) {

						}
					} else {
						//Dispatch Eclub login event
						setTimeout(() => {
							document.dispatchEvent(new CustomEvent("eclub-missing"));
						}, 100);
					}
				}
			},
		};
	}, [session, trackingInitiated]);

	function collectUTMTags() {
		let url = new URL(window.location.href);
		const urlTags = ['utm_source', 'utm_medium', 'utm_campaign'];

		if(url.searchParams.get(urlTags[0]) || url.searchParams.get(urlTags[1]) | url.searchParams.get(urlTags[2])) {
			let trackingData = {
				source: url.searchParams.get(urlTags[0]),
				medium: url.searchParams.get(urlTags[1]),
				campaign: url.searchParams.get(urlTags[2])
			}

			sessionStorage.setItem('trackingData', JSON.stringify(trackingData));
		}
	}

	/**
	 * Initialize tracking
	 */
	useEffect(() => {
		if(session.token && !isGoogleBot()) {
			context.actions.init();
		}
	}, [session.token]);

	/**
	 * Cookie listeners
	 */
	useEffect(() => {
		let waitCount = 0;
		(function wait() {
			if(Cookies.get('_fbp')) {
				consumeQueue();
			} else {
				if(waitCount < 20) {
					setTimeout(wait, 250);
				}

				waitCount++;
			}
		})();
	}, []);

	/**
	 * Event listeners
	 */
	useEffect(() => {
		if(session.token) {
			document.addEventListener("add-to-cart", cartHasUpdated);
			document.addEventListener("has-updated-cart", cartHasUpdated);
			document.addEventListener("remove-from-cart", cartHasUpdated);
		}

		return () => {
			document.removeEventListener('add-to-cart', cartHasUpdated);
			document.removeEventListener('has-updated-cart', cartHasUpdated);
			document.removeEventListener('remove-from-cart', cartHasUpdated);
		}
	}, [session.token, cart]);

	return <TrackingContext.Provider value={context}>
		{!isInStoreDisplay() ? <Head>
			<script data-cookieconsent="ignore" dangerouslySetInnerHTML={{__html: `
					window.dataLayer = window.dataLayer || [];
					
					function gtag() {
						dataLayer.push(arguments);
					}
					
					gtag("consent", "default", {
						ad_personalization: "denied",
						ad_storage: "denied",
						ad_user_data: "denied",
						analytics_storage: "denied",
						functionality_storage: "denied",
						personalization_storage: "denied",
						security_storage: "granted",
						wait_for_update: 500,
					});
					
					gtag("set", "ads_data_redaction", true);
				`}}/>
			<script
			  id="Cookiebot"
			  src="https://consent.cookiebot.com/uc.js"
			  data-cbid="40c1ea95-4502-4abf-b287-4d10db7f8517"
			  type="text/javascript"
			  data-consentmode-defaults="disabled"
			/>
		</Head> : ''}
		{children}
	</TrackingContext.Provider>;
}
